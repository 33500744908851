import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Box, Typography } from '@mui/material';
import { useThemePallette } from 'shared';
/**
 * アップロードされたファイルの情報を表示するコンポーネント
 */
export const FileItem = ({ name, size, type }) => {
    const palette = useThemePallette();
    const formatFileSize = (bytes) => {
        if (bytes === 0)
            return '0 B';
        const k = 1024;
        const sizes = ['B', 'KB', 'MB', 'GB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(1))} ${sizes[i]}`;
    };
    return (_jsxs(Box, { display: "flex", alignItems: "center", gap: 2, p: 2, bgcolor: palette.container.background, borderRadius: 2, border: 1, borderColor: palette.container.border, children: [_jsx(Box, { display: "flex", alignItems: "center", justifyContent: "center", bgcolor: palette.container.iconBackground, borderRadius: 1, p: 1, children: _jsx(InsertDriveFileIcon, { sx: {
                        fontSize: 24,
                        color: palette.container.iconColor,
                    } }) }), _jsxs(Box, { flex: 1, children: [_jsx(Typography, { variant: "body2", fontWeight: 500, mb: 0.5, children: name }), _jsxs(Typography, { variant: "caption", color: "text.secondary", children: [formatFileSize(size), " \u2022 ", type] })] })] }));
};
