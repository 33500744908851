import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from '@mui/material';
import { useThemePallette } from 'shared';
import { FileItem } from './FileItem';
import { FlexCol } from '../../mui';
/**
 * アップロードされたファイルのリストを表示するコンポーネント
 */
export const FileList = ({ files }) => {
    const palette = useThemePallette();
    return (_jsxs(FlexCol, { gap: 2, children: [_jsx(Typography, { variant: "subtitle1", fontWeight: 600, children: "\u30A2\u30C3\u30D7\u30ED\u30FC\u30C9\u30D5\u30A1\u30A4\u30EB" }), files.length ? (files.map((file, index) => (_jsx(FileItem, { name: file.name, size: file.size, type: file.type }, index)))) : (_jsx(Box, { p: 3, bgcolor: palette.container.background, borderRadius: 2, border: 1, borderColor: palette.container.border, children: _jsx(Typography, { variant: "body2", color: "text.secondary", textAlign: "center", children: "\u30D5\u30A1\u30A4\u30EB\u304C\u9078\u629E\u3055\u308C\u3066\u3044\u307E\u305B\u3093" }) }))] }));
};
