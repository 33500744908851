import { dataToURLString } from 'aspida';
var api = function (_a) {
    var baseURL = _a.baseURL, fetch = _a.fetch;
    var prefix = (baseURL === undefined ? 'https://kotobuk.jp/api/v0' : baseURL).replace(/\/$/, '');
    var PATH0 = '/atnd-user';
    var PATH1 = '/healthcheck/files';
    var PATH2 = '/healthcheck/files/upload';
    var PATH3 = '/healthcheck/ocr';
    var PATH4 = '/healthcheck/record';
    var PATH5 = '/healthcheck/standard';
    var PATH6 = '/healthcheck-file';
    var PATH7 = '/records';
    var PATH8 = '/login-staff';
    var PATH9 = '/refresh-staff-token';
    var PATH10 = '/user';
    var PATH11 = '/healthcheck/records';
    var GET = 'GET';
    var POST = 'POST';
    var DELETE = 'DELETE';
    var PATCH = 'PATCH';
    return {
        atnd_user: {
            _atnd_user_id: function (val1) {
                var prefix1 = "".concat(PATH0, "/").concat(val1);
                return {
                    /**
                     * ユーザーを取得する。主にAtnd側からKotobukのユーザー情報を取得する際に本APIを使用する。
                     * @returns Success
                     */
                    get: function (option) {
                        return fetch(prefix, prefix1, GET, option).json();
                    },
                    /**
                     * ユーザーを取得する。主にAtnd側からKotobukのユーザー情報を取得する際に本APIを使用する。
                     * @returns Success
                     */
                    $get: function (option) {
                        return fetch(prefix, prefix1, GET, option)
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    $path: function () { return "".concat(prefix).concat(prefix1); },
                };
            },
        },
        healthcheck: {
            files: {
                _healthcheck_file_id: function (val2) {
                    var prefix2 = "".concat(PATH1, "/").concat(val2);
                    return {
                        /**
                         * 特定の健康診断ファイルを取得する。
                         * @returns Success
                         */
                        get: function (option) {
                            return fetch(prefix, prefix2, GET, option).json();
                        },
                        /**
                         * 特定の健康診断ファイルを取得する。
                         * @returns Success
                         */
                        $get: function (option) {
                            return fetch(prefix, prefix2, GET, option)
                                .json()
                                .then(function (r) { return r.body; });
                        },
                        /**
                         * 特定の健康診断ファイルを削除する。
                         */
                        delete: function (option) {
                            return fetch(prefix, prefix2, DELETE, option).send();
                        },
                        /**
                         * 特定の健康診断ファイルを削除する。
                         */
                        $delete: function (option) {
                            return fetch(prefix, prefix2, DELETE, option)
                                .send()
                                .then(function (r) { return r.body; });
                        },
                        $path: function () { return "".concat(prefix).concat(prefix2); },
                    };
                },
                upload: {
                    /**
                     * csv, png, jpg, pdf形式の健康診断結果ファイルをアップロードする。csvの場合は、DBに登録する。 png, jpg, pdfの場合はOCRを実行のためKotobukの分析APIを呼び出す。
                     * @returns Successfully uploaded
                     */
                    post: function (option) {
                        return fetch(prefix, PATH2, POST, option, 'FormData').json();
                    },
                    /**
                     * csv, png, jpg, pdf形式の健康診断結果ファイルをアップロードする。csvの場合は、DBに登録する。 png, jpg, pdfの場合はOCRを実行のためKotobukの分析APIを呼び出す。
                     * @returns Successfully uploaded
                     */
                    $post: function (option) {
                        return fetch(prefix, PATH2, POST, option, 'FormData')
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    $path: function () { return "".concat(prefix).concat(PATH2); },
                },
                /**
                 * 健康診断ファイルの一覧を取得する。
                 * @returns Success
                 */
                get: function (option) {
                    return fetch(prefix, PATH1, GET, option).json();
                },
                /**
                 * 健康診断ファイルの一覧を取得する。
                 * @returns Success
                 */
                $get: function (option) {
                    return fetch(prefix, PATH1, GET, option)
                        .json()
                        .then(function (r) { return r.body; });
                },
                $path: function (option) {
                    return "".concat(prefix).concat(PATH1).concat(option && option.query ? "?".concat(dataToURLString(option.query)) : '');
                },
            },
            ocr: {
                _healthcheck_file_id: function (val2) {
                    var prefix2 = "".concat(PATH3, "/").concat(val2);
                    return {
                        /**
                         * 健康診断結果ファイルのOCRの実行結果を取得する。
                         * @returns Success
                         */
                        get: function (option) {
                            return fetch(prefix, prefix2, GET, option).json();
                        },
                        /**
                         * 健康診断結果ファイルのOCRの実行結果を取得する。
                         * @returns Success
                         */
                        $get: function (option) {
                            return fetch(prefix, prefix2, GET, option)
                                .json()
                                .then(function (r) { return r.body; });
                        },
                        /**
                         * 健康診断結果ファイルのOCRの実行結果を更新する。
                         * @returns Success
                         */
                        patch: function (option) {
                            return fetch(prefix, prefix2, PATCH, option).json();
                        },
                        /**
                         * 健康診断結果ファイルのOCRの実行結果を更新する。
                         * @returns Success
                         */
                        $patch: function (option) {
                            return fetch(prefix, prefix2, PATCH, option)
                                .json()
                                .then(function (r) { return r.body; });
                        },
                        $path: function () { return "".concat(prefix).concat(prefix2); },
                    };
                },
            },
            record: {
                _healthcheck_record_id: function (val2) {
                    var prefix2 = "".concat(PATH4, "/").concat(val2);
                    return {
                        /**
                         * 特定のユーザーのhealthcheck_idに指定した健康診断結果を取得する。
                         * @returns Success
                         */
                        get: function (option) {
                            return fetch(prefix, prefix2, GET, option).json();
                        },
                        /**
                         * 特定のユーザーのhealthcheck_idに指定した健康診断結果を取得する。
                         * @returns Success
                         */
                        $get: function (option) {
                            return fetch(prefix, prefix2, GET, option)
                                .json()
                                .then(function (r) { return r.body; });
                        },
                        /**
                         * 特定のユーザーのhealthcheck_idに指定した健康診断結果を更新する。
                         * @returns Success
                         */
                        patch: function (option) {
                            return fetch(prefix, prefix2, PATCH, option).json();
                        },
                        /**
                         * 特定のユーザーのhealthcheck_idに指定した健康診断結果を更新する。
                         * @returns Success
                         */
                        $patch: function (option) {
                            return fetch(prefix, prefix2, PATCH, option)
                                .json()
                                .then(function (r) { return r.body; });
                        },
                        /**
                         * 特定のユーザーのhealthcheck_idに指定した健康診断結果を削除する。
                         */
                        delete: function (option) {
                            return fetch(prefix, prefix2, DELETE, option).send();
                        },
                        /**
                         * 特定のユーザーのhealthcheck_idに指定した健康診断結果を削除する。
                         */
                        $delete: function (option) {
                            return fetch(prefix, prefix2, DELETE, option)
                                .send()
                                .then(function (r) { return r.body; });
                        },
                        $path: function () { return "".concat(prefix).concat(prefix2); },
                    };
                },
                /**
                 * 特定のユーザーの健康診断結果を登録する。
                 * @returns Success
                 */
                post: function (option) {
                    return fetch(prefix, PATH4, POST, option).json();
                },
                /**
                 * 特定のユーザーの健康診断結果を登録する。
                 * @returns Success
                 */
                $post: function (option) {
                    return fetch(prefix, PATH4, POST, option)
                        .json()
                        .then(function (r) { return r.body; });
                },
                $path: function () { return "".concat(prefix).concat(PATH4); },
            },
            standard: {
                /**
                 * 健康診断の各項目の基準値を取得する。
                 * @returns Success
                 */
                get: function (option) {
                    return fetch(prefix, PATH5, GET, option).json();
                },
                /**
                 * 健康診断の各項目の基準値を取得する。
                 * @returns Success
                 */
                $get: function (option) {
                    return fetch(prefix, PATH5, GET, option)
                        .json()
                        .then(function (r) { return r.body; });
                },
                $path: function () { return "".concat(prefix).concat(PATH5); },
            },
        },
        healthcheck_file: {
            _healthcheck_file_id: function (val1) {
                var prefix1 = "".concat(PATH6, "/").concat(val1);
                return {
                    records: {
                        /**
                         * 特定の健康診断ファイルに紐づく健康診断データを取得する。
                         * @returns Success
                         */
                        get: function (option) {
                            return fetch(prefix, "".concat(prefix1).concat(PATH7), GET, option).json();
                        },
                        /**
                         * 特定の健康診断ファイルに紐づく健康診断データを取得する。
                         * @returns Success
                         */
                        $get: function (option) {
                            return fetch(prefix, "".concat(prefix1).concat(PATH7), GET, option)
                                .json()
                                .then(function (r) { return r.body; });
                        },
                        $path: function (option) {
                            return "".concat(prefix).concat(prefix1).concat(PATH7).concat(option && option.query ? "?".concat(dataToURLString(option.query)) : '');
                        },
                    },
                };
            },
        },
        login_staff: {
            /**
             * Kotobukスタッフログイン
             * @returns Success
             */
            post: function (option) {
                return fetch(prefix, PATH8, POST, option).json();
            },
            /**
             * Kotobukスタッフログイン
             * @returns Success
             */
            $post: function (option) {
                return fetch(prefix, PATH8, POST, option)
                    .json()
                    .then(function (r) { return r.body; });
            },
            $path: function () { return "".concat(prefix).concat(PATH8); },
        },
        refresh_staff_token: {
            /**
             * Kotobukスタッフのアクセストークンを再取得する
             * @returns Success
             */
            post: function (option) {
                return fetch(prefix, PATH9, POST, option).json();
            },
            /**
             * Kotobukスタッフのアクセストークンを再取得する
             * @returns Success
             */
            $post: function (option) {
                return fetch(prefix, PATH9, POST, option)
                    .json()
                    .then(function (r) { return r.body; });
            },
            $path: function () { return "".concat(prefix).concat(PATH9); },
        },
        user: {
            _user_id: function (val1) {
                var prefix1 = "".concat(PATH10, "/").concat(val1);
                return {
                    healthcheck: {
                        records: {
                            /**
                             * 特定のユーザーの健康診断履歴を取得する。
                             * @returns Success
                             */
                            get: function (option) {
                                return fetch(prefix, "".concat(prefix1).concat(PATH11), GET, option).json();
                            },
                            /**
                             * 特定のユーザーの健康診断履歴を取得する。
                             * @returns Success
                             */
                            $get: function (option) {
                                return fetch(prefix, "".concat(prefix1).concat(PATH11), GET, option)
                                    .json()
                                    .then(function (r) { return r.body; });
                            },
                            $path: function (option) {
                                return "".concat(prefix).concat(prefix1).concat(PATH11).concat(option && option.query ? "?".concat(dataToURLString(option.query)) : '');
                            },
                        },
                    },
                    /**
                     * ユーザーを取得する。
                     * @returns Success
                     */
                    get: function (option) {
                        return fetch(prefix, prefix1, GET, option).json();
                    },
                    /**
                     * ユーザーを取得する。
                     * @returns Success
                     */
                    $get: function (option) {
                        return fetch(prefix, prefix1, GET, option)
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    /**
                     * ユーザーを更新する。
                     * @returns Success
                     */
                    patch: function (option) {
                        return fetch(prefix, prefix1, PATCH, option).json();
                    },
                    /**
                     * ユーザーを更新する。
                     * @returns Success
                     */
                    $patch: function (option) {
                        return fetch(prefix, prefix1, PATCH, option)
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    /**
                     * ユーザーを削除する。
                     */
                    delete: function (option) {
                        return fetch(prefix, prefix1, DELETE, option).send();
                    },
                    /**
                     * ユーザーを削除する。
                     */
                    $delete: function (option) {
                        return fetch(prefix, prefix1, DELETE, option)
                            .send()
                            .then(function (r) { return r.body; });
                    },
                    $path: function () { return "".concat(prefix).concat(prefix1); },
                };
            },
            /**
             * ユーザーを登録する。
             * @returns Success
             */
            post: function (option) {
                return fetch(prefix, PATH10, POST, option).json();
            },
            /**
             * ユーザーを登録する。
             * @returns Success
             */
            $post: function (option) {
                return fetch(prefix, PATH10, POST, option)
                    .json()
                    .then(function (r) { return r.body; });
            },
            $path: function () { return "".concat(prefix).concat(PATH10); },
        },
    };
};
export default api;
