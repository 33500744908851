export var WEEKDAY_COLORS = {
    sunday: '#d32f2f', // 日曜日
    saturday: '#1976d2', // 土曜日
};
export var RESERVATION_STATUS_COLORS = {
    AVAILABLE: '#E53E3E', // 空きあり・やや混雑
    BUSY: '#3E58B6', // 混雑
    UNAVAILABLE: '#718096', // 空きなし
    WALK_IN: '#6f42c1', // 来院順受付
};
