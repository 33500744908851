import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TableCell, TableRow, Typography } from '@mui/material';
import { ReservationCalendarCell } from './ReservationCalendarCell';
export const ReservationCalendarRow = ({ dates, disabledReservationStatuses, openConfirmDialog, slot, }) => {
    const slotsArray = [
        slot.day0,
        slot.day1,
        slot.day2,
        slot.day3,
        slot.day4,
        slot.day5,
        slot.day6,
    ];
    // キリの良い時間は太字にしてメリハリをつける
    const isRolloverTime = slot.start.slice(3, 5) === '00';
    return (_jsxs(TableRow, { children: [_jsx(TableCell, { align: "right", sx: {
                    p: 1,
                    pr: { xs: 1, sm: 2 },
                    minWidth: { xs: 40, sm: undefined },
                    maxWidth: { xs: 12, sm: 10 },
                }, children: _jsx(Typography, { fontSize: isRolloverTime ? { xs: 12, sm: 15 } : { xs: 11, sm: 12 }, fontWeight: isRolloverTime ? 'bold' : undefined, children: slot.start }) }), slotsArray.map((slotStatus, index) => {
                return (_jsx(ReservationCalendarCell, { status: slotStatus, openConfirmDialog: () => {
                        openConfirmDialog(`${dates[index]} ${slot.start}:00`);
                    }, disabledReservationStatuses: disabledReservationStatuses }, index));
            })] }));
};
