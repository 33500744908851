import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Typography } from '@mui/material';
import { AppButton } from '../../Button';
import { FlexCol } from '../../mui';
/**
 * ファイルをドロップできるエリアを表示するコンポーネント
 */
export const DropArea = ({ getInputProps, getRootProps, isDragActive, }) => {
    const inputProps = getInputProps();
    return (_jsxs(FlexCol, Object.assign({ alignItems: "center", justifyContent: "center", p: 2, height: 180, border: "2px dashed #CCC", borderRadius: 4, color: "#454545", bgcolor: isDragActive ? '#F2F2F2' : '#F2F2F28C' }, getRootProps(), { children: [_jsx(CloudUploadIcon, { fontSize: "large", color: "inherit" }), _jsx(Typography, { my: 1.5, color: "GrayText", children: "\u753B\u50CF\u30D5\u30A1\u30A4\u30EB\u3092\u30C9\u30E9\u30C3\u30B0\uFF06\u30C9\u30ED\u30C3\u30D7\u3059\u308B\u304B\u3001\u4E0B\u306E\u30DC\u30BF\u30F3\u304B\u3089\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044" }), _jsxs(AppButton, { variant: "outlined", sx: { bgcolor: '#FFF', width: 140 }, children: [_jsx("input", Object.assign({ type: "file", id: "file-upload", style: { display: 'none' }, multiple: true }, inputProps)), _jsx("label", { htmlFor: "file-upload", children: "\u30D5\u30A1\u30A4\u30EB\u3092\u9078\u629E" })] })] })));
};
