var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { useState } from 'react';
import { parseCalendarDate, useCalendarState, useSnackbar } from 'shared';
import { ReservationCalendarRow } from './ReservationCalendarRow';
import { ReservationConfirmDialog } from './ReservationConfirmDialog';
import { WeekdayCellLayout } from './layout/WeekdayCellLayout';
import { ReservationCalendarSkeleton } from '.';
export const ReservationCalendar = ({ ccid, clinicAlias, currentDate, isOnline, isPatient = false, memo, mode = 'day', onSelect, onSubmit, reservation_estimation_time, }) => {
    const { openSnackbar } = useSnackbar();
    const { isLoading, slotList } = useCalendarState({
        isOnline,
        date: currentDate,
        ccid,
        clinicAlias,
        mode,
    });
    const [reserveData, setReserveData] = useState(undefined);
    if (isLoading)
        return _jsx(ReservationCalendarSkeleton, {});
    if (!slotList)
        return _jsx(_Fragment, {});
    // mapでUIを作成するために配列にする
    const dates = [
        slotList.day0,
        slotList.day1,
        slotList.day2,
        slotList.day3,
        slotList.day4,
        slotList.day5,
        slotList.day6,
    ];
    const handleClickSlot = (reservation_time) => {
        // ユーザーシステムの場合は予約日をセットするだけ
        if (onSelect) {
            onSelect(reservation_time);
        }
        else {
            // 予約確定ダイアログを開く
            const payload = Object.assign(Object.assign({ url_alias: clinicAlias, reservation_time }, (reservation_estimation_time && {
                reservation_estimation_time,
                observation_estimation_time: Number(reservation_estimation_time),
            })), { is_dummy: false, is_online: false, memo });
            setReserveData(payload);
        }
    };
    const onClose = () => {
        setReserveData(undefined);
    };
    const submit = (memo) => __awaiter(void 0, void 0, void 0, function* () {
        if (!reserveData)
            return;
        return onSubmit === null || onSubmit === void 0 ? void 0 : onSubmit(Object.assign(Object.assign({}, reserveData), { memo })).then(() => {
            openSnackbar('予約が完了しました', 'success');
        }).catch(() => {
            openSnackbar('サーバーエラーが発生しました', 'error');
        });
    });
    return (_jsxs(_Fragment, { children: [_jsx(Table, { sx: {
                    border: '1px solid #BFB9B0',
                    borderCollapse: 'separate',
                    borderRadius: 1,
                    overflow: `scroll`,
                }, children: _jsxs(TableBody, { children: [_jsxs(TableRow, { children: [_jsx(TableCell, { sx: { borderRight: '1px solid rgba(224, 224, 224, 1)' } }), dates.map((date) => {
                                    const { MMDD: mmdd, weekday } = parseCalendarDate(date);
                                    const isSaturday = weekday === '(土)';
                                    const isSunday = weekday === '(日)';
                                    const color = isSaturday
                                        ? '#3182CE'
                                        : isSunday
                                            ? '#E53E3E'
                                            : '#333';
                                    return (_jsx(WeekdayCellLayout, { weekdayElement: weekday, dateElement: mmdd, color: color }, date));
                                })] }), slotList.slots.map((slot) => {
                            return (_jsx(ReservationCalendarRow, { dates: dates, slot: slot, openConfirmDialog: handleClickSlot, disabledReservationStatuses: isPatient
                                    ? // 患者: 満席, 休診日, 当日来院は予約不可
                                        ['full', 'closed', 'walk_in']
                                    : // 病院: 休診日のみ予約不可
                                        ['closed'] }, `${slot.start}~${slot.end}`));
                        })] }) }), _jsx(ReservationConfirmDialog, { reserveData: reserveData, onClose: onClose, submit: submit })] }));
};
