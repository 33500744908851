'use client';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { MenuItem, Select, TextField, Typography, } from '@mui/material';
import { useState } from 'react';
/**
 * 15分単位で時刻を選択できるTimePicker
 */
export const TimePicker = ({ inputRef, disabled = false, error = false, helperText, onChange, value, }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [inputValue, setInputValue] = useState(value);
    // 00:00から23:45までの15分単位の時刻を生成
    const timeOptions = Array.from({ length: 24 * 4 }).map((_, index) => {
        const hour = Math.floor(index / 4);
        const minute = (index % 4) * 15;
        return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
    });
    const handleTimeChange = (newValue) => {
        // 時刻のバリデーション
        const timeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
        if (timeRegex.test(newValue)) {
            onChange(newValue);
            setInputValue(newValue);
            setIsEditing(false);
        }
    };
    return (_jsxs(_Fragment, { children: [isEditing ? (_jsx(TextField, { inputRef: inputRef, value: inputValue, onChange: (e) => setInputValue(e.target.value), onBlur: () => handleTimeChange(inputValue), onKeyDown: (e) => {
                    if (e.key === 'Enter') {
                        handleTimeChange(inputValue);
                    }
                }, size: "small", sx: { width: 100 }, disabled: disabled, error: error, helperText: helperText, inputProps: {
                    pattern: '[0-2][0-9]:[0-5][0-9]',
                } })) : (_jsx(Select, { inputRef: inputRef, value: value, onChange: (e) => handleTimeChange(e.target.value), onClick: () => setIsEditing(true), size: "small", sx: { width: 100 }, disabled: disabled, error: error, MenuProps: {
                    PaperProps: {
                        sx: {
                            maxHeight: 300,
                            '& .MuiMenuItem-root': {
                                py: 0.5,
                                minHeight: 'unset',
                                fontSize: '0.875rem',
                            },
                        },
                    },
                }, children: timeOptions.map((time) => (_jsx(MenuItem, { value: time, children: time }, time))) })), !isEditing && helperText && (_jsx(Typography, { variant: "caption", color: error ? 'error' : 'text.secondary', sx: { mt: 0.5, display: 'block' }, children: helperText }))] }));
};
