'use client';
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useController } from 'react-hook-form';
import { TimePicker } from '../inputs';
/**
 * react-hook-formラッパー
 */
export const RhfTimePicker = (_a) => {
    var { control, disabled, error, helperText, name } = _a, restProps = __rest(_a, ["control", "disabled", "error", "helperText", "name"]);
    const { field: { onChange, ref, value }, fieldState: { error: fieldError, isDirty }, } = useController({ name, control });
    console.log({ name, value, fieldError, isDirty });
    return (_jsx(TimePicker, Object.assign({ inputRef: ref, value: value, onChange: onChange, disabled: disabled, error: error || !!fieldError, helperText: helperText || (fieldError === null || fieldError === void 0 ? void 0 : fieldError.message) }, restProps)));
};
