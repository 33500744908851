import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ImageIcon from '@mui/icons-material/Image';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Box, Typography } from '@mui/material';
import { Flex, FlexCol } from '../../../../components';
const fileTypes = [
    {
        icon: _jsx(PictureAsPdfIcon, {}),
        label: 'PDF',
        extensions: ['.pdf'],
        iconColor: '#F90808',
    },
    {
        icon: _jsx(ImageIcon, {}),
        label: '画像',
        extensions: ['.jpg', '.jpeg', '.png'],
        iconColor: '#059669',
    },
];
/**
 * アップロード可能なファイル形式の情報を表示するコンポーネント
 * ファイルタイプごとにアイコン、ラベル、対応する拡張子を表示します
 */
export const FileTypeInfo = () => (_jsxs(Box, { bgcolor: "#f6f8ff", borderRadius: 3, p: 3, sx: { boxShadow: '0 2px 12px rgba(0, 0, 0, 0.04)' }, children: [_jsxs(Flex, { gap: 1, alignItems: "center", mb: 2, children: [_jsx(InfoOutlinedIcon, { color: "info", fontSize: "small" }), _jsx(Typography, { variant: "subtitle1", fontWeight: 600, children: "\u30A2\u30C3\u30D7\u30ED\u30FC\u30C9\u53EF\u80FD\u306A\u30D5\u30A1\u30A4\u30EB" })] }), _jsx(Flex, { gap: 2, flexWrap: "wrap", children: fileTypes.map(({ extensions, icon, iconColor, label }) => (_jsxs(Flex, { gap: 1, bgcolor: "white", py: 1, px: 2, borderRadius: 2, border: 1, borderColor: "divider", alignItems: "center", sx: {
                    transition: 'all 0.2s ease',
                    '&:hover': {
                        transform: 'translateY(-1px)',
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
                    },
                }, children: [_jsx(Box, { sx: { color: iconColor, fontSize: 20 }, children: icon }), _jsxs(FlexCol, { children: [_jsx(Typography, { variant: "body2", fontWeight: 600, children: label }), _jsx(Typography, { variant: "caption", color: "text.secondary", children: extensions.join(', ') })] })] }, label))) })] }));
