import { R } from './remeda';
export var parseBoolean = function (value) {
    switch (value.toLowerCase().trim()) {
        case 'true':
            return true;
        case 'false':
            return false;
        default:
            return value;
    }
};
export var createConverter = function (excludes, options) {
    return function (data) {
        var _a;
        var result = {};
        for (var _i = 0, _b = Object.entries(data); _i < _b.length; _i++) {
            var prop = _b[_i];
            var key = prop[0];
            var value = prop[1];
            if (!excludes.includes(key)) {
                var processors = options === null || options === void 0 ? void 0 : options.processors;
                if (processors) {
                    var processedValue = (processors === null || processors === void 0 ? void 0 : processors[key])
                        ? (_a = processors[key]) === null || _a === void 0 ? void 0 : _a.call(processors, value)
                        : value;
                    if (!processedValue)
                        continue;
                    result[key] = processedValue;
                }
                else {
                    result[key] = value;
                }
            }
        }
        return result;
    };
};
export var transformNumber = function (value) {
    var num = Number(value);
    if (R.isNumber(num)) {
        return num;
    }
    else {
        return undefined;
    }
};
export var transformNumberArray = function (value) {
    if (R.isArray(value)) {
        return value.map(function (v) { return Number(v); }).filter(function (v) { return R.isNumber(v); });
    }
    else {
        return undefined;
    }
};
export var transformString = function (value) {
    if (R.isString(value)) {
        return value;
    }
    else {
        return undefined;
    }
};
export var transformStringArray = function (value) {
    if (R.isArray(value)) {
        return value.map(function (v) { return String(v); }).filter(function (v) { return R.isString(v); });
    }
    else {
        return undefined;
    }
};
export var convertToHalfWidth = function (str) {
    return str
        .replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    })
        .replace(/[！-～]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });
};
export var getFilterParamsFromHash = function (excludeKeys) {
    var result = {};
    var hash = window.location.hash;
    if (!hash)
        return result;
    var decodedHash = decodeURIComponent(hash);
    var keyValuePairs = decodedHash.substring(1).split('&');
    for (var _i = 0, keyValuePairs_1 = keyValuePairs; _i < keyValuePairs_1.length; _i++) {
        var pair = keyValuePairs_1[_i];
        var _a = pair.split('='), key = _a[0], value = _a[1];
        if (excludeKeys === null || excludeKeys === void 0 ? void 0 : excludeKeys.includes(key))
            continue;
        var normalizedValue = decodeURIComponent(value.replace(/\+/g, ' '));
        try {
            var parsedValue = JSON.parse(normalizedValue);
            var isEmptyArray = Array.isArray(parsedValue) && parsedValue.length === 0;
            if (isEmptyArray)
                continue;
            result[key] = parsedValue;
        }
        catch (error) {
            console.error('getFilterParamsFromHash:', error);
            result[key] = normalizedValue;
        }
    }
    return result;
};
