import { createTheme } from '@mui/material';
export const theme = createTheme({
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    borderColor: '#D4DAE2',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontSize: 16,
                },
            },
        },
    },
    shape: {
        borderRadius: 2,
    },
    typography: {
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 700,
        h1: { fontSize: '1.6rem' },
        h2: { fontSize: '1.3rem' },
        h3: { fontSize: '1.2rem' },
        h4: { fontSize: '1.1rem' },
        h5: { fontSize: '1.0rem' },
        h6: { fontSize: '0.9rem' },
        subtitle1: { fontSize: 18 },
        body1: { fontSize: 14 },
        body2: { fontSize: 13 },
        button: { textTransform: 'none' },
    },
    palette: {
        primary: {
            main: '#27B46A',
            light: '#23B189',
            dark: '#1E8E5A',
            contrastText: '#FFF',
            100: '#E6F8F0',
            200: '#C1EEDB',
            300: '#9DE4C6',
            400: '#78DAB1',
            500: '#53D09C',
            600: '#4DBF8E',
        },
        // 灰色
        secondary: {
            main: '#999',
            light: '#CCC',
            dark: '#666',
            contrastText: '#FFF',
        },
        error: {
            main: '#E4726A',
            light: '#FFB7B2',
            dark: '#C7534D',
            contrastText: '#FFF',
        },
        warning: {
            main: '#FF9800',
            light: '#FFB74D',
            dark: '#F57C00',
            contrastText: '#FFF',
        },
        info: {
            main: '#2196F3',
            light: '#64B5F6',
            dark: '#1558d6',
            contrastText: '#FFF',
        },
        success: {
            main: '#4CAF50',
            light: '#81C784',
            dark: '#388E3C',
            contrastText: '#FFF',
        },
        contrast: {
            main: '#FFF',
            light: '#FFF',
            dark: '#FFF',
        },
        text: {
            primary: '#333',
            secondary: '#666',
            disabled: '#999',
        },
        background: {
            default: '#FFF',
            paper: '#FFF',
        },
        container: {
            background: '#F8FAFC',
            iconBackground: '#EEF2FF',
            iconColor: '#6366F1',
            border: '#E2E8F0',
        },
    },
});
