import { jsx as _jsx } from "react/jsx-runtime";
import { TableCell } from '@mui/material';
import { RESERVATION_STATUS_COLORS } from 'shared';
export const ReservationCalendarCell = ({ disabledReservationStatuses, openConfirmDialog, status, }) => {
    const isDisabled = disabledReservationStatuses.includes(status);
    return (_jsx(TableCell, { onClick: () => {
            if (isDisabled)
                return;
            openConfirmDialog();
        }, sx: Object.assign(Object.assign({ lineHeight: 1 }, getStatusStyle(status)), { p: 0, textAlign: 'center', fontWeight: 'bold', cursor: isDisabled ? 'default' : 'pointer' }), "data-cy": isDisabled ? 'unavailable-calendar-cel' : 'available-calendar-cell', children: statusText(status) }));
};
const getStatusStyle = (status) => {
    switch (status) {
        case 'full':
            return {
                color: RESERVATION_STATUS_COLORS.UNAVAILABLE,
                backgroundColor: '#EDF2F7',
                fontSize: { xs: 16, sm: 18 },
                lineHeight: 1.9,
                '&:hover': {
                    backgroundColor: '#CBD5E0',
                },
            };
        case 'high':
            return {
                color: RESERVATION_STATUS_COLORS.BUSY,
                backgroundColor: '#FFF',
                fontSize: { xs: 12, sm: 15 },
                lineHeight: 0.2,
                '&:hover': {
                    backgroundColor: '#CBD5E0',
                },
            };
        case 'moderate':
            return {
                color: RESERVATION_STATUS_COLORS.AVAILABLE,
                backgroundColor: '#FFF',
                fontSize: { xs: 12, sm: 15 },
                '&:hover': {
                    backgroundColor: '#FED7D7',
                },
            };
        case 'low':
            return {
                color: RESERVATION_STATUS_COLORS.AVAILABLE,
                backgroundColor: '#FFF',
                fontSize: { xs: 14, sm: 16 },
                '&:hover': {
                    backgroundColor: '#FED7D7',
                },
            };
        case 'closed':
            return {
                color: RESERVATION_STATUS_COLORS.UNAVAILABLE,
                backgroundColor: '#EDF2F7',
                fontSize: { xs: 12, sm: 15 },
            };
        case 'walk_in':
            return {
                color: RESERVATION_STATUS_COLORS.WALK_IN,
                backgroundColor: '#EDF2F7',
                fontSize: { xs: 12, sm: 15 },
            };
    }
};
const statusText = (status) => {
    switch (status) {
        case 'full':
            return '×';
        case 'high':
            return '△';
        case 'moderate':
            return '◯';
        case 'low':
            return '◎';
        case 'closed':
            return '休';
        case 'walk_in':
            return '☆';
    }
};
